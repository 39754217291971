import { createApp, h } from 'vue';
import { reactive } from 'vue';
import './registerServiceWorker';
import moment from 'moment';
import AppWrapper from './AppWrapper.vue'; 
import router from './router';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import BlockUI from 'primevue/blockui';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import Editor from 'primevue/editor';
import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
 

//FONT AWSOME INSTALLATION
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
//END

const app = createApp({
  router,
  render() { return h(AppWrapper); }
});


app.use(PrimeVue, { ripple: true, inputStyle: 'filled' });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router); 
// app.use(moment);

app.config.globalProperties.$appState = reactive({ sideBarColor: 'white', modelClass: 'modal-body', RTL: false, isNewThemeLoaded: false, layoutMode: 'light' });

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('styleclass', StyleClass);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('Editor ', Editor); 
app.component('BlockUI ', BlockUI);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('CascadeSelect', CascadeSelect);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FullCalendar', FullCalendar);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);

app.component('BlockViewer', BlockViewer);

import { Form } from 'vform';
import {
  HasError,
  AlertError,
  AlertErrors,
  AlertSuccess
} from 'vform/src/components/tailwind'
app.component(HasError.name, HasError)
app.component(AlertError.name, AlertError)
app.component(AlertErrors.name, AlertErrors)
app.component(AlertSuccess.name, AlertSuccess)
//IMPORT CUSTOME PLUGINS AND PACKAGES
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
// import VueGoogleAutocomplete from "vue-google-autocomplete"; 
// app.component('VueGoogleAutocomplete', VueGoogleAutocomplete);

import VueGoogleMaps from '@fawmi/vue-google-maps'
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBHql0mGiANZEr3VChFeU2ukpBmO9Yv140',
  },
});
//Google Map Autocomplete
import VueGeolocation from "vue3-geolocation";
import GMaps from "vuejs3-google-maps";
app.use(VueGeolocation);
app.use(GMaps, {
  load: {
    apiKey: "AIzaSyBHql0mGiANZEr3VChFeU2ukpBmO9Yv140",
    libraries: ["places"],
  },
});




import EventBus from './event-bus';
app.use(EventBus);
// app.config.globalProperties.EventBus = EventBus;

import axios from "axios";
import { $axios } from './http-common';
axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('token');
import MyPlugin from "./plugins/MyPlugin";
import "vue-toastification/dist/index.css";
import './registerServiceWorker'
import "./assets/sass/overrides/_layout_styles.scss";

import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true; // add this line
app.use(VueClipboard);

import CKEditor from '@ckeditor/ckeditor5-vue';
app.use(CKEditor);

// app.component('font-awesome-icon', faUserSecret);
app.use(MyPlugin);
//DEFINE GLOBAL VARIABLES 
app.config.globalProperties.$token = '';
app.config.globalProperties.$baseurl = process.env.VUE_APP_BASE_URL; //'http://127.0.0.1/hostel/backend/';
app.config.globalProperties.filesUrl = process.env.VUE_APP_FILE_URL; //'http://127.0.0.1/hostel/backend/public/storage/';
// app.config.globalProperties.$baseurl = 'http://hostel.cmhlmc.com/backend/';
// app.config.globalProperties.filesUrl = 'http://hostel.cmhlmc.com/backend/public/storage/';
app.config.globalProperties.studentFiles = process.env.VUE_APP_STUDENT_FILES; //'//fs.cmhlmc.com/storage/students/files/';

app.config.globalProperties.isRTL = false;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.axios = $axios;
app.config.globalProperties.showBtnLabels = window.innerWidth <= 991 ? false : true;
app.config.globalProperties.toolBarBtnSize = 'md';
app.config.globalProperties.toolBarPosition = 'start';
app.config.globalProperties.Form = Form;
app.config.globalProperties.Router = router;
app.config.globalProperties.moment = moment;
app.config.globalProperties.RecordStatuses = [{ 'id': 1, 'name': 'Active' }, { 'id': 0, 'name': 'Blocked' }];
app.config.globalProperties.appName = 'Question Bank';
app.config.globalProperties.permissions = [];
app.config.globalProperties.userData = [];
if (localStorage.getItem('token')) {
  // localStorage.clear(); 
  app.config.globalProperties.token = localStorage.getItem('token');
}else {
  localStorage.clear();
}
if (localStorage.getItem('permissions')) {
  app.config.globalProperties.permissions = [];
}
if (localStorage.getItem('user')) {
  app.config.globalProperties.userData = JSON.parse(localStorage.getItem('user'));
}
const Emittery = require('emittery');
const emitter = new Emittery();
app.use(emitter);
app.config.globalProperties.emitter = emitter;
$axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403) {
      emitter.emit('accessDenied');
    }
    return error;
  }
);

import print from 'vue3-print-nb';
app.use(print);
import Vue3Barcode from 'vue3-barcode';
app.use(Vue3Barcode);

// console.log(process.env.VUE_APP_BASE_URL)
app.mount('#app');
