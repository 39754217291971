import { createRouter, createWebHashHistory } from 'vue-router';
// console.log(this.menu);
const routes = [
    
    {
        path:'/subjects',
        name:'subjects',
        component: () => import('./pages/Subjects/Subjects.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Subjects' }],
        }
    },{
        path:'/question-types',
        name:'question-types',
        component: () => import('./pages/QuestionTypes/QuestionTypes.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Question Types' }],
        }
    },{
        path:'/programs',
        name:'programs',
        component: () => import('./pages/Programs/Programs.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Programs' }],
        }
    },
    {
        path:'/designations',
        name:'designations',
        component: () => import('./pages/Designations/Designations.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Designations' }],
        }
    },
    {
        path:'/departments',
        name:'departments',
        component: () => import('./pages/Departments/Departments.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Departments' }],
        }
    },
    {
        path:'/questions',
        name:'questions',
        component: () => import('./pages/QuestionBank/Questions.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Questions' }],
        }
    },
    {
        path:'/users',
        name:'users',
        component: () => import('./pages/Users/Users.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Users' }],
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./pages/Dashboards/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Dashboard' }],
        }
    },
    {
        name: 'dashboardRoute',
        path: '/',
        component: () => import('./pages/Dashboards/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Dashboard' }],
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./pages/Logout.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Logout' }],
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./pages/Register.vue')
    },

    {
        name: 'settings',
        path: '/settings',
        component: () => import('./pages/Settings.vue'),
        meta: {
            breadcrumb: [{ parent: 'Profile Settings', label: 'Settings' }],
        }
    }, 
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;